<template>
  <div v-if="retailer && retailer.Description" class="retailerInformation">
    <!-- <Transition name="fade" mode="out-in"> -->
    <div class="retailerInfo">
      <a
        v-if="map"
        class="retailerInfo__map"
        :href="googleMapUrl"
        target="_blank"
        rel="noopener"
        @click="onMapClick"
      >
        <img
          class="image-aspect--banner"
          :src="map"
          :alt="`Map of Showroom ${retailerName}`"
        />
      </a>
      <OsSkeletonLoader
        v-else
        width="100%"
        :height="skeletonHeight"
        class="retailerInfoSkeleton"
      />
      <div class="retailerInfo__row">
        <span class="retailerInfo__name text-lg bold">
          {{ retailerName }}
        </span>
        <a :href="googleMapUrl" target="_blank" rel="noopener">
          <pre class="text-base font-sans inline-link retailerInfo__address">
              {{ retailer.Street }}
              {{ retailer.City }}
              {{ retailer.Postcode }}
            </pre
          >
        </a>
      </div>
      <template v-if="showDriveTime && retailer.DriveTime">
        <div class="retailerInfo__row">
          <span class="text-base text-gray-500">
            <OsIcon name="DriveTime" />
            {{ asMinutes(retailer.DriveTime) }}
          </span>
        </div>
      </template>
      <template v-else-if="showLinks">
        <div v-if="retailerPhone || retailer.Email" class="retailerInfo__row">
          <a
            v-if="retailerPhone"
            :href="`tel:${retailerPhone}`"
            class="inline-link"
            @click="track('phone')"
          >
            <OsIcon name="Phone" class="text-blue-600" />
            <span>{{ retailerPhone }}</span>
          </a>
          <a
            v-if="retailer.Email"
            :href="`mailto:${retailer.Email}`"
            class="inline-link"
            @click="track('email')"
          >
            <OsIcon name="Email" class="text-blue-600" />
            <span>{{ retailer.Email }}</span>
          </a>
        </div>
      </template>
      <div class="retailerInfo__row">
        <RetailerOpeningTimes
          v-if="hasOpeningTimes && openingHoursEnabled"
          :retailer="retailer"
          :vehicle="vehicle"
          :page-category="pageCategory"
        />
      </div>
      <OsAlert
        v-if="showDisclaimer"
        color="gray"
        variant="soft"
        class="rounded-t-none"
      >
        <template #description>
          This car is located at
          <strong>{{ owningRetailerDescription }}</strong> and will be fulfilled
          by <strong>{{ retailerName }}</strong
          >.
        </template>
      </OsAlert>
    </div>
    <!-- </Transition> -->
    <div v-if="isCloudfrontRetailers" class="mt-4">
      <p>
        <span>This {{ modelDescription }} is only available from </span>
        <span>{{ retailerName }}</span
        >. If you want to change it,
        <a
          class="inline-link bold"
          @click="goToResults('change your preferred Showroom')"
          >change your preferred Showroom</a
        >
        on the
        <a class="inline-link bold" @click="goToResults(vehicle?.Model)"
          >{{ modelDescription }} results</a
        >
        page.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { ERouteConditions, ERoutes } from '~/types/routes'
import { IRetailer } from '~/types/retailer'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import { useRetailerStore } from '~/stores/retailerStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFiltersStore } from '~/stores/filtersStore'

export default {
  name: 'RetailerInformation',

  props: {
    retailer: {
      type: Object as PropType<IRetailer>,
      required: true
    },
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: false,
      default: null
    },
    showCtas: {
      type: Boolean,
      required: false,
      default: true
    },
    isSmart: {
      type: Boolean,
      required: false,
      default: false
    },
    showLinks: {
      type: Boolean,
      required: false,
      default: true
    },
    showDriveTime: {
      type: Boolean,
      required: false,
      default: false
    },
    trackMap: {
      type: Boolean,
      required: false,
      default: true
    },
    skeletonHeight: {
      type: String,
      required: false,
      default: '363px'
    },
    showCloudfrontMessage: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['go-to-results'],

  setup(props, { emit }) {
    const { $dataLayer } = useNuxtApp()
    const vehicleRetailer = ref<IRetailer | null>(null)
    const retailerStore = useRetailerStore()
    const customerTypeStore = useCustomerTypeStore()
    const router = useRouter()
    const route = useRoute()
    const filtersStore = useFiltersStore()
    const { hideSidepanel } = useSidepanel(ESidepanel.FORMS)
    const {
      retailerAddress,
      retailerName,
      retailerPhone,
      owningRetailer,
      owningRetailerDescription,
      isNotOwningRetailer,
      hasOpeningTimes
    } = useRetailer(
      toRef(props, 'retailer'),
      toRef(props, 'vehicle'),
      vehicleRetailer,
      toRef(props, 'isSmart')
    )
    const { asMinutes } = useUi()
    const { isNew } = useVehicle(toRef(props, 'vehicle'))

    const { isEnabled: openingHoursEnabled } = useFeatureFlag(
      'opening_hours_enabled'
    )

    const showDisclaimer = computed<boolean>(
      () =>
        owningRetailer.value! &&
        isNotOwningRetailer.value &&
        props.vehicle?.VehicleType === 'USED'
    )

    const isCloudfrontRetailers = computed<boolean>(() => {
      if (!props.showCloudfrontMessage) return false

      return !!props.vehicle?.Model && isNew.value
    })

    const onMapClick = () => {
      if (!props.trackMap) return

      $dataLayer.linkClick({
        pageCategory: 'retailer',
        category: 'map interaction',
        action: 'map click',
        retailer: props.retailer,
        ...(props.vehicle ? { vehicle: vehicleAnalytics(props.vehicle) } : {})
      })
    }

    const track = (page: string) => {
      $dataLayer.linkClick({
        pageCategory: 'retailer',
        category: 'product',
        action: `retailer:${page}`
      })
    }

    vehicleRetailer.value = props.retailer || retailerStore.retailer

    const { map, googleMapUrl } = useRetailerMap(
      props.retailer || retailerStore.retailer,
      {
        scale: 2,
        size: '398x166',
        zoom: 13
      }
    )

    const modelDescription = computed<string>(
      () =>
        (props.isSmart
          ? props.vehicle?.Model?.toLowerCase()
          : props.vehicle?.Model) || ''
    )

    const pageCondition = computed<ERouteConditions>(() => {
      return (
        (route.params.condition as ERouteConditions) || ERouteConditions.New
      )
    })

    const goToResults = (trackingString?: string) => {
      hideSidepanel()

      router
        .push({
          name: ERoutes.Results,
          params: {
            condition: pageCondition.value,
            type: customerTypeStore.getCustomerTypeRouteParam
          },
          query: filtersStore.getActiveQueryParams
        } as any)
        .catch(() => {})
      window.scroll({ top: 0, behavior: 'smooth' })

      emit('go-to-results', trackingString)
    }

    const currentForm = inject<Ref<string>>('currentForm', ref(''))
    const currentStep = inject<Ref<{ label: string }>>(
      'currentStep',
      ref({ label: '' })
    )

    const pageCategory = computed(() => {
      if (currentForm?.value) {
        return `${currentForm?.value}:${currentStep?.value.label}`
      }
      return 'preferred showroom'
    })

    return {
      track,
      map,
      googleMapUrl,
      retailerPhone,
      asMinutes,
      retailerName,
      showDisclaimer,
      retailerAddress,
      hasOpeningTimes,
      owningRetailerDescription,
      onMapClick,
      goToResults,
      modelDescription,
      isNew,
      isCloudfrontRetailers,
      pageCategory,
      openingHoursEnabled
    }
  }
}
</script>

<style lang="scss" scoped>
.retailerInfo__address {
  display: flex;
  color: $grey--dark;
  white-space: pre-line;
}
</style>

<style lang="scss">
.retailerInformation {
  position: relative;
}
.retailerInfo {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: $shadow;

  .retailerInfo__name {
    margin-bottom: rem(8);
    display: flex;
    font-family: $mb-font-text-bold;
    font-size: rem(16);
    line-height: 1;
    color: $black--light;
  }

  .disclaimer {
    margin-bottom: 0;
  }
}

.retailerInfo__map {
  display: inline-block;
  background-color: $grey--light;
  padding: 0;
  width: 100%;
  line-height: 0;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.retailerInfo__row {
  margin: 0 rem(8);
  padding: rem(16) 0;

  @include viewport('sm') {
    margin: 0 rem(8);
    padding: rem(16) rem(8);
  }

  & ~ .retailerInfo__row {
    padding-top: 0;
  }

  .retailerInfo__name {
    display: block;
    margin-bottom: rem(8);
    color: $black--light;
  }

  a {
    text-decoration: none;
    display: block;
    margin-bottom: rem(8);
  }

  svg {
    vertical-align: sub;
  }
}

.retailerInfo__contact--email {
  line-height: 1.3;
}
</style>
